import { EnvCommon } from '@tmf-env/types';

// // eslint-disable-next-line @typescript-eslint/typedef
export const envBaseCommon: Pick<EnvCommon, 'langs' | 'svgIconConfig'> = {
  langs: ['en', 'pl'],
  svgIconConfig: {
    brand: 'januszcasino',
    hostUrl: 'https://image-assets-server-dot-cms-headless-gae.ew.r.appspot.com/'
  }
};
