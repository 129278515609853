import { configureLocalization } from '@lit/localize';
import { EnvTma } from '@tmf-env/types';
import { extractCookies } from '@tmf-shared-platform/cookie-storage/pure-utils/extract-cookies';

type LocalizeFns = {
  getLocale: () => string;
  setLocale(locale: string): void;
};

function detectLang(): string | undefined {
  const pathname: string[] = window.location.pathname.split('/');
  const langSegment: string | undefined = pathname?.[1];
  return langSegment?.length > 0 ? langSegment : undefined;
}

async function loadLocalize(lang: string): Promise<Record<string, any>> {
  if (lang === 'pl') {
    // eslint-disable-next-line @typescript-eslint/typedef
    return await import('@tmf-i18n/janusz-casino/generated/locales/pl').then((mod) => mod.templates);
  }
  if (lang === 'hu') {
    // eslint-disable-next-line @typescript-eslint/typedef
    return await import('@tmf-i18n/janusz-casino/generated/locales/hu').then((mod) => mod.templates);
  }
  if (lang === 'de') {
    // eslint-disable-next-line @typescript-eslint/typedef
    return await import('@tmf-i18n/janusz-casino/generated/locales/de').then((mod) => mod.templates);
  }
  return {};
}

function langFromMemory(): string | undefined {
  return localStorage.getItem('app_lang') ?? undefined;
}

function langToMemory(lang: string): void {
  localStorage.setItem('app_lang', lang);
}

export async function bootstrapAppI18n(env: EnvTma): Promise<void> {
  const lang: string | undefined = detectLang();
  if (lang && !env.langs.includes(lang)) {
    window.location.href = `/`;
    return;
  }
  if (!lang) {
    const cookies: Record<string, string> = extractCookies(document.cookie);
    const defaultCountry: string =
      env.langs.find((lang: string) => cookies['tmfCountry'] && lang === cookies['tmfCountry'].toLowerCase()) ??
      env.langs[0];
    window.location.href = `/${langFromMemory() ?? defaultCountry}/`;
    return;
  }
  langToMemory(lang!);
  const translations: Record<string, string> = await loadLocalize(lang!);
  const functions: LocalizeFns = configureLocalization({
    sourceLocale: 'en',
    targetLocales: env.langs,
    loadLocale: async () => ({
      templates: translations
    })
  });
  functions.setLocale(lang!);
}
