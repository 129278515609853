import { provideSingleton } from '@bcf-vanilla-ts-v1-shared/di/provide-singleton';
import { QueryParams } from '@tmf-shared-platform/activated-route/activated-route';
import { HistoryUrl } from '@tmf-shared-platform/history-url/history-url';
import { Router } from '@tmf-shared-platform/router/internal';
import { notFoundRoutePath } from '@tmf-ui-routes/paths/not-found.route-path';
import { fromEvent } from 'rxjs';
import { routerGoBack, routerUpdateReplaceUrl, routerUpdateUrl } from './router-utils';

export class RouterNavigatorService {
  private _router: Router | undefined;
  private _historyUrl: HistoryUrl | undefined;

  public bindRouter(router: Router): void {
    this._router ??= router;
    this._initWatcher();
  }

  public bindHistoryUrl(historyUrl: HistoryUrl): void {
    this._historyUrl = historyUrl;
  }

  public navigateTo(path: string, queryParams?: QueryParams, state?: Record<string, any>): void {
    if (!this._router) {
      return;
    }
    routerUpdateUrl(this._router, path, queryParams, state);
  }

  public goBack(): void {
    if (!this._historyUrl) {
      return;
    }
    routerGoBack(this._historyUrl);
  }

  private _initWatcher(): void {
    fromEvent(window, 'routerNotFound').subscribe(() => {
      routerUpdateReplaceUrl(
        this._router!,
        notFoundRoutePath(), // + `?notFoundpathname=${window.location.pathname}`,
        undefined,
        { notFoundPath: window.location.pathname }
      );
    });
  }
}

export function provideRouterNavigatorService(): RouterNavigatorService {
  return provideSingleton(RouterNavigatorService, () => new RouterNavigatorService());
}
